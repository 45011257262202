import { Link } from "react-router-dom";
import kickLogo from "../assets/kick.png";
import { useState } from "react";

const updatesTwitter = "https://twitter.com/xQcUpdates";
const updatesDiscord = "https://discord.gg/xqcupdates";
const xqcTwitter = "https://twitter.com/xQc";
const xqcTwitch = "https://twitch.tv/xqc";
const xqcKick = "https://kick.com/xqc";

const buttonClasses =
  "bg-transparent text-lg hover:text-black m-2 min-w-[160px] font-semibold py-4 px-4 mx-4 w-full font-bold border hover:border-transparent rounded";
export default function Home(props) {
  const [hovering, setHovering] = useState(false);
  return (
    <div
      className={"h-screen w-full bg-cover text-white font-['Open_Sans']"}
      style={{ backgroundImage: `url(${props.background})` }}
    >
      <script
        src="https://kit.fontawesome.com/a17aba8cf0.js"
        crossOrigin="anonymous"
        type="text/javascript"
      ></script>
      <div className={"grid h-screen place-items-center"}>
        <div>
          <h1 className={"text-7xl text-center font-bold"}>xQcUpdates</h1>
          <p className={"text-2xl text-center font-medium m-2"}>
            <i>The</i> place for updates regarding xQc
          </p>
          <div
            className={
              "grid md:grid-cols-5 lg:grid-cols-6 grid-cols-2 sm:grid-cols-3 gap-2 font-bold"
            }
          >
            <a href={xqcTwitch}>
              <button
                className={
                  buttonClasses + " border-purple-500 hover:bg-purple-500"
                }
              >
                xQc <i className="fab fa-twitch" aria-hidden="true" />
              </button>
            </a>{" "}
            <a href={xqcKick}>
              <button
                className={
                  buttonClasses + " border-green-500 hover:bg-green-500"
                }
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                xQc{" "}
                <img
                  alt="Kick logo"
                  style={{ width: 18, height: 18, marginLeft: 5 }}
                  src={kickLogo}
                  className={`${hovering ? "invert" : ""}`}
                />
              </button>
            </a>{" "}
            <a href={xqcTwitter}>
              <button
                className={buttonClasses + " border-blue-400 hover:bg-blue-400"}
              >
                xQc <i className="fab fa-twitter" aria-hidden="true" />
              </button>
            </a>
            <a href={updatesTwitter}>
              <button
                className={buttonClasses + " border-blue-400 hover:bg-blue-400"}
              >
                xQcUpdates <i className="fab fa-twitter" aria-hidden="true" />
              </button>
            </a>
            <a href={updatesDiscord}>
              <button
                className={buttonClasses + " border-blue-600 hover:bg-blue-600"}
              >
                xQcUpdates <i className="fab fa-discord" aria-hidden="true" />
              </button>
            </a>
            <Link to={"/emotes"}>
              <button
                className={buttonClasses + " border-white hover:bg-white"}
              >
                xQc Emotes
                <i className="fab fa-face-smile" aria-hidden="true" />
              </button>
            </Link>
          </div>
          {/* <p className={"text-2xl text-center"}>
            Check out our browser extension
          </p>
          <div className="flex text-5xl justify-center text-current">
            <a
              href="https://microsoftedge.microsoft.com/addons/detail/xqcowupdates/bepljkddjgmcnkeeljhknclmjmanpfdl"
              className="m-2 hover:opacity-50"
            >
              <i className="fab fa-edge" aria-hidden="true" />
            </a>
            <a
              href="https://chrome.google.com/webstore/detail/xqcowupdates/njncikdkmkhlfnpidgfdhjfenebjgmga"
              className="m-2 hover:opacity-50"
            >
              <i className="fab fa-chrome" aria-hidden="true" />
            </a>
          </div> */}  
        </div>
      </div>
    </div>
  );
}
