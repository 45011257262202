import Emote from "./Emote.jsx";
export default function EmoteContainer(props) {
  if (props.loading) {
    return <div className={" bg-gray-500 opacity-90 "}>Loading emotes...</div>;
  } else {
    return (
      <div
        className={
          "grid bg-gray-700 opacity-95 grid-cols-3 sm:grid-cols-5 md:grid-cols-9 lg:grid-cols-12 xl:grid-cols-15"
        }
      >
        {props.emotes.map((emote) => {
          return <Emote key={emote.id} emote={emote} source={props.source} />;
        })}
      </div>
    );
  }
}
