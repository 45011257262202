//emote sources
import BTTVEmote from "./sources/BTTVEmote";
import FFZEmote from "./sources/FFZEmote";
import SevenTvEmote from "./sources/SevenTvEmote";

export default function Emote(props) {
  switch (props.source) {
    case "bttv":
      return <BTTVEmote emote={props.emote} />;
    case "ffz":
      return <FFZEmote emote={props.emote} />;
    case "7tv":
      return <SevenTvEmote emote={props.emote} />;
    default:
      break;
  }
}
