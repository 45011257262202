import { useState } from "react";
import EmoteOverlay from "../EmoteOverlay";

export default function SevenTvEmote(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [imageWidth, setImageWidth] = useState(64);
  const onImgLoad = ({ target: img }) => {
    setImageWidth(img.offsetWidth);
  };
  let twoCol = false;
  if (
    props.emote.data.host.files[0].width -
    props.emote.data.host.files[0].height >=
    10
  ) {
    twoCol = true;
  }
  return (
    <div
      className={`flex ${twoCol ? "col-span-2" : ""}`}
      onMouseOver={(e) => {
        setShowOverlay(true);
      }}
      onMouseOut={(e) => {
        setShowOverlay(false);
      }}
    >
      <EmoteOverlay
        show={showOverlay}
        wide={twoCol}
        name={props.emote.name}
        width={imageWidth}
        link={"https://cdn.7tv.app/emote/" + props.emote.id + "/3x.webp"}
      />

      <img
        onLoad={onImgLoad}
        key={props.emote.id}
        name={props.emote.data.name}
        src={"https://cdn.7tv.app/emote/" + props.emote.id + "/3x.webp"}
        className={`h-16 w-auto m-2 `}
      />
    </div>
  );
}
