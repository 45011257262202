import { useState } from "react";
import EmoteOverlay from "../EmoteOverlay";
export default function BTTVEmote(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [imageWidth, setImageWidth] = useState(64);
  const onImgLoad = ({ target: img }) => {
    setImageWidth(img.offsetWidth);
  };
  return (
    <div
      className=" "
      onMouseOver={(e) => {
        setShowOverlay(true);
      }}
      onMouseOut={(e) => {
        setShowOverlay(false);
      }}
    >
      <EmoteOverlay
        show={showOverlay}
        width={imageWidth}
        wide={false}
        name={props.emote.code}
        link={"https://cdn.betterttv.net/emote/" + props.emote.id + "/2x"}
      />
      <img
        onLoad={onImgLoad}
        key={props.emote.id}
        src={"https://cdn.betterttv.net/emote/" + props.emote.id + "/2x"}
        className={` h-16 w-auto z-0 m-2 relative`}
      />
    </div>
  );
}
