import { useState } from "react";
import EmoteOverlay from "../EmoteOverlay";

export default function FFZEmote(props) {
  let twoCol = false;
  let imgSize = 2;
  if (props.emote.width - props.emote.height >= 10) {
    twoCol = true;
    imgSize = 4;
  }
  const [showOverlay, setShowOverlay] = useState(false);
  const [imageWidth, setImageWidth] = useState(64);
  const onImgLoad = ({ target: img }) => {
    setImageWidth(img.offsetWidth);
  };
  return (
    <div
      className={`flex ${twoCol ? "col-span-2" : ""}`}
      onMouseOver={(e) => {
        setShowOverlay(true);
      }}
      onMouseOut={(e) => {
        setShowOverlay(false);
      }}
    >
      <EmoteOverlay
        show={showOverlay}
        name={props.emote.name}
        wide={twoCol}
        width={imageWidth}
        link={"https://cdn.frankerfacez.com/emoticon/" + props.emote.id + "/" + imgSize}
      />
      <img
        key={props.emote.id}
        onLoad={onImgLoad}
        name={props.emote.name}
        src={"https://cdn.frankerfacez.com/emoticon/" + props.emote.id + "/" + imgSize}
        className={`h-16 w-auto m-2 ${twoCol ? "col-span-2" : ""}`}
      />
    </div>
  );
}
