import { useState } from "react";

export default function EmoteOverlay(props) {
  const [buttonText, setButtonText] = useState(props.name);
  const buttonClass =
    "bg-black text-xs hover:bg-gray-600 w-full text-opacity-100 hover:border-transparent mt-2 rounded transition duration-500 ease-in-out";
  let overlayWidth = "w-[64px]";
  switch (true) {
    case props.width >= 64 && props.width < 74:
      overlayWidth = "w-[64px]";
      break;
    case props.width >= 75 && props.width < 90:
      overlayWidth = "w-[75px]";
      break;
    case props.width >= 91 && props.width < 105:
      overlayWidth = "w-[91px]";
      break;
    case props.width >= 106 && props.width < 110:
      overlayWidth = "w-[106px]";
      break;
    case props.width >= 111 && props.width < 135:
      overlayWidth = "w-[111px]";
      break;
    case props.width >= 136 && props.width < 150:
      overlayWidth = "w-[136px]";
      break;
    case props.width >= 151 && props.width < 157:
      overlayWidth = "w-[151px]";
      break;
    case props.width >= 158 && props.width < 175:
      overlayWidth = "w-[158px]";
      break;
    case props.width >= 176 && props.width < 191:
      overlayWidth = "w-[176px]";
      break;
    case props.width >= 192 && props.width < 200:
      overlayWidth = "w-[192px]";
      break;
    case props.width >= 201 && props.width < 219:
      overlayWidth = "w-[201px]";
      break;
    case props.width >= 220 && props.width < 225:
      overlayWidth = "w-[220px]";
      break;
    case props.width >= 226 && props.width < 250:
      overlayWidth = "w-[226px]";
      break;
    default:
      break;
  }
  if (props.show) {
    return (
      <div
        className={`absolute transition duration-300 ease-in-out bg-opacity-70 bg-gray-500 text-white z-10 mt-2 text-center h-[64px] translate-x-2 ${overlayWidth}`}
      >
        <button
          name={props.name}
          className={buttonClass}
          onClick={(e) => {
            setButtonText("Copied!");
            setTimeout(() => {
              setButtonText(props.name);
            }, 3000);
            navigator.clipboard.writeText(e.target.name);
          }}
        >
          {buttonText}
        </button>
        <button
          name={props.link}
          className={buttonClass}
          onClick={(e) => {
            navigator.clipboard.writeText(e.target.name);
          }}
        >
          Copy url
        </button>
      </div>
    );
  } else {
    return "";
  }
}
