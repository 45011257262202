import { useState, useEffect } from "react";

//components
import EmoteContainer from "../components/EmoteContainer";

//assets
import BTTVLogo from "../assets/bttv.png";
import FFZLogo from "../assets/ffz.png";
import SevenTVLogo from "../assets/7tv.png";

export default function Emotes(props) {
  const [bttvEmotes, setBttvEmotes] = useState([]);
  const [bttvLoading, setBttvLoading] = useState(true);
  const [ffzEmotes, setFfzEmotes] = useState([]);
  const [ffzLoading, setFfzLoading] = useState(true);
  const [twitchEmotes, setTwitchEmotes] = useState([]);
  const [twitchLoading, setTwitchLoading] = useState(true);
  const [sevenTvEmotes, setSevenTvEmotes] = useState([]);
  const [sevenTvLoading, setSevenTvLoading] = useState(true);

  useEffect(() => {
    fetch("https://7tv.io/v3/users/twitch/71092938").then(async (res) => {
      const formatted7tv = await res.json();
      setSevenTvEmotes(formatted7tv.emote_set.emotes);
      setSevenTvLoading(false);
    });
    fetch("https://api.betterttv.net/3/cached/users/twitch/71092938").then(
      async (res) => {
        const formattedBttv = await res.json();
        const combintedEmotes = formattedBttv.channelEmotes.concat(
          formattedBttv.sharedEmotes
        );
        setBttvEmotes(combintedEmotes);
        setBttvLoading(false);
      }
    );
    fetch("https://api.frankerfacez.com/v1/room/xqc").then(async (res) => {
      const formattedFfz = await res.json();
      setFfzEmotes(formattedFfz.sets[formattedFfz.room.set].emoticons);
      setFfzLoading(false);
    });
  }, []);

  return (
    <div
      className={"  text-white font-sans bg-repeat"}
      style={{ backgroundImage: `url(${props.background})` }}
    >
      <div className="w-70 justify-center text-center mx-1">
        <div className=" ">
          <h1 className="text-7xl font-bold bg-gray-600 w-fit text-center">
            xQc's Emotes
          </h1>
        </div>
        <p className="w-screen bg-[#1A202C] flex text-center justify-center p-1 mt-1">
          <img src={BTTVLogo} className="w-6 h-6" />
          BTTV Emotes
        </p>
        <EmoteContainer
          emotes={bttvEmotes}
          loading={bttvLoading}
          source={"bttv"}
        />
        <p className="w-screen bg-[#1A202C] flex text-center justify-center p-1 mt-1">
          <img src={FFZLogo} className="w-6 h-6" />
          FFZ Emotes
        </p>
        <EmoteContainer
          emotes={ffzEmotes}
          loading={ffzLoading}
          source={"ffz"}
        />
        <p className="w-screen bg-[#356BB6] flex text-center justify-center p-1 mt-1">
          <img src={SevenTVLogo} className="w-6 h-6" />
          7TV Emotes
        </p>
        <EmoteContainer
          emotes={sevenTvEmotes}
          loading={sevenTvLoading}
          source={"7tv"}
        />
      </div>
    </div>
  );
}
