import "./index.css";
import { Routes, Route } from "react-router-dom";
import background from "./assets/background.jpg";

//pages
import Home from "./pages/home";
import Emotes from "./pages/emotes";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home background={background} />} />
      <Route exact path="/emotes" element={<Emotes background={background} />} />
    </Routes>
  );
}

export default App;
